import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import {
  BottomTextContainer,
  HorizontalContainer,
  HorizontalSmallImage,
  Image,
  SmallHorizontalCard,
} from '../styles/TabFeatureCard.styled';

const TabFeatureHorizontalCard = ({
  tab,
  activeTab,
  setActiveTab,
  totalTabs,
  isSmall = false,
}) => {
  const [touchPosition, setTouchPosition] = useState(null);
  const { callToAction, cardBody, tabButtonName, tabCardImage } = tab;

  const handleTouchStart = e => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = e => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5 && activeTab !== totalTabs - 1) {
      setActiveTab(activeTab + 1);
    }

    if (diff < -5 && activeTab !== 0) {
      setActiveTab(activeTab - 1);
    }

    setTouchPosition(null);
  };
  if (isSmall) {
    return (
      <SmallHorizontalCard>
        <HorizontalSmallImage>
          <GatsbyImage
            image={tabCardImage.gatsbyImageData}
            alt={tabButtonName}
            loading='eager'
          />
        </HorizontalSmallImage>
        <div className="body-container">
          {cardBody && (
            <div className="horizontal-card-subhead">{cardBody}</div>
          )}
          {callToAction && (
            <CallToAction
              link={callToAction.url}
              value={callToAction.label}
              variant="tertiary"
              className="card-cta"
            />
          )}
        </div>
      </SmallHorizontalCard>
    );
  }
  return (
    <HorizontalContainer>
        <Image>
          <GatsbyImage
            image={tabCardImage.gatsbyImageData}
            alt={tabButtonName}
          />
        </Image>
        <BottomTextContainer>
          {cardBody && (
            <div className="horizontal-card-subhead">
              <p>
                {cardBody}
              </p>
            </div>
          )}
          {callToAction && (
            <CallToAction
              link={callToAction.url}
              value={callToAction.label}
              variant="tertiary"
            />
          )}
        </BottomTextContainer>
      </HorizontalContainer>
  )
};

export default TabFeatureHorizontalCard;
